var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', [_c('v-divider', {
    staticClass: "grey darken-4 mb-20px mb-md-30px",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('v-row', [_vm._l(_vm.boards, function (board) {
    var _board$writer, _board$createdAt, _board$createdAt$toDa;
    return [_c('v-col', {
      key: board._id,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "overflow-hidden",
      attrs: {
        "outlined": "",
        "rounded": "lg",
        "to": `${_vm.$route.path}/${board._id}`
      }
    }, [_c('div', {
      staticClass: "gallery-img",
      style: `background-image: url('${board === null || board === void 0 ? void 0 : board.thumb}')`
    }), _c('div', {
      staticClass: "pa-16px pa-md-20px"
    }, [_c('div', {
      staticClass: "txt txt--sm mb-12px"
    }, [_vm._v(_vm._s(board === null || board === void 0 ? void 0 : (_board$writer = board.writer) === null || _board$writer === void 0 ? void 0 : _board$writer.name))]), _c('div', {
      staticClass: "txt txt--sm txt--dark font-weight-bold text-truncate-2",
      staticStyle: {
        "height": "calc(1.35em * 2)"
      }
    }, [_vm._v(_vm._s(board === null || board === void 0 ? void 0 : board.subject))]), _c('div', {
      staticClass: "txt txt--xs txt--light mt-12px mt-md-24px"
    }, [_vm._v(_vm._s(board === null || board === void 0 ? void 0 : (_board$createdAt = board.createdAt) === null || _board$createdAt === void 0 ? void 0 : (_board$createdAt$toDa = _board$createdAt.toDate) === null || _board$createdAt$toDa === void 0 ? void 0 : _board$createdAt$toDa.call(_board$createdAt)))])])])], 1)];
  })], 2)], 1), _c('div', {
    staticClass: "pagination-wrap pagination-wrap--sm"
  }, [_vm._t("pagination")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }