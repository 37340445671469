<template>
    <div class="board-container">
        <div>
            <v-divider class="grey darken-4 mb-20px mb-md-30px" style="border-width: 2px 0 0" />
            <v-row>
                <template v-for="board in boards">
                    <v-col cols="6" md="4" :key="board._id">
                        <v-card outlined rounded="lg" class="overflow-hidden" :to="`${$route.path}/${board._id}`">
                            <div class="gallery-img" :style="`background-image: url('${board?.thumb}')`"></div>
                            <div class="pa-16px pa-md-20px">
                                <div class="txt txt--sm mb-12px">{{ board?.writer?.name }}</div>
                                <div class="txt txt--sm txt--dark font-weight-bold text-truncate-2" style="height: calc(1.35em * 2)">{{ board?.subject }}</div>
                                <div class="txt txt--xs txt--light mt-12px mt-md-24px">{{ board?.createdAt?.toDate?.() }}</div>
                            </div>
                        </v-card>
                    </v-col>
                </template>
            </v-row>
        </div>

        <div class="pagination-wrap pagination-wrap--sm">
            <slot name="pagination" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        boards: { type: Array, default: null },
        summary: { type: Object, default: () => ({ totalCount: 0 }) },

        page: { type: Number, default: 1 },
        limit: { type: Number, default: 10 },
    },
    computed: {
        count() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    methods: {
        changePage(page) {
            this.$emit("page", page);
        },
    },
};
</script>

<style lang="scss" scoped>
.gallery-img {
    width: 100%;
    padding-top: calc(282 / 426 * 100%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
</style>
